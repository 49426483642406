import {
  Alternative,
  Button,
  Form,
  LysaFormRef,
  RadioGroupCard,
  RequiredValidator,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import {
  Beneficiary,
  DEFAULT_PAYOUT_AGE,
  DEFAULT_WITHDRAWAL_MONTHS,
  useTransfer,
} from "../TransferContext";
import {
  LIFE_LONG_ALTERNATIVE_VALUE,
  LONGEST_WITHDRAWAL_PERIOD,
  SHORTEST_WITHDRAWAL_PERIOD,
  UP_TO_MAX_AGE,
} from "../withdrawalPlan/WithdrawalPlan";
import { useHistory } from "react-router";
import { getUserAge } from "../utils/userAge";
import { defineMessages, useIntl } from "react-intl";
import { AnimatePresence, motion } from "framer-motion";
import { useUser } from "../../../../../context/UserContext";
import { Modal } from "../../../../../components/modal/Modal";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./PayoutPlan.scss";

const messages = defineMessages({
  customWithdrawalPeriodPreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-period.alternative",
  },
  customWithdrawalPeriodDefaultPreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-period.default-alternative",
  },
  lifeLong: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-period.alternative.life-long",
  },
  customWithdrawalAgePreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-age.alternative",
  },
  customWithdrawalAgeDefaultPreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-age.default-alternative",
  },
});

interface Props {
  next: () => void;
}

export const PayoutPlan: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const user = useUser();
  const history = useHistory();
  const age = getUserAge(user.tin);
  const formRef = useRef<LysaFormRef>();
  const [transfer, setTransfer] = useTransfer();
  const [showModal, setShowModal] = useState(false);
  const [illegalCombination, setIllegalCombination] = useState(false);
  const [withdrawalAge, setWithdrawalAge] = useState<number>(
    transfer.withdrawalAge
  );
  const [withdrawalMonths, setWithdrawalMonths] = useState<number>(
    transfer.withdrawalMonths
  );
  const [lifeLong, setLifeLong] = useState<boolean>(transfer.lifeLong);
  const [repayment, setRepayment] = useState<boolean>(transfer.repayment);
  const [beneficiary, setBeneficiary] = useState(transfer.beneficiary);

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (!age) {
    return null;
  }

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  /**
   * It should be possible to select a withdrawal period ranging from 5 years up to 40 years, but with a maximum limit of reaching the age of 100.
   * For example, if the customer is 60 years old, they can choose a 40-year withdrawal period;
   * if they are 70, the maximum is 30 years;
   * if they are 80, the maximum is 20 years, and so on.
   */

  const selectedWithdrawalAge = withdrawalAge;
  const maxWithdrawalPeriod = Math.min(
    UP_TO_MAX_AGE - selectedWithdrawalAge,
    LONGEST_WITHDRAWAL_PERIOD
  );

  const withdrawalPeriodAlternatives = generateYearArray(
    SHORTEST_WITHDRAWAL_PERIOD,
    maxWithdrawalPeriod
  ).map(
    (year): Alternative<number> => ({
      text: intl.formatMessage(
        year === DEFAULT_WITHDRAWAL_MONTHS / 12
          ? messages.customWithdrawalPeriodDefaultPreference
          : messages.customWithdrawalPeriodPreference,
        {
          years: year,
        }
      ),
      value: year,
    })
  );

  withdrawalPeriodAlternatives.push({
    text: intl.formatMessage(messages.lifeLong),
    value: LIFE_LONG_ALTERNATIVE_VALUE,
  });

  const withdrawalAgeEnd = Math.min(100 - withdrawalMonths / 12, 80);

  const withdrawalAgeAlternatives = generateYearArray(
    Math.max(age, 55),
    withdrawalAgeEnd
  ).map(
    (age): Alternative<number> => ({
      text: intl.formatMessage(
        age === DEFAULT_PAYOUT_AGE
          ? messages.customWithdrawalAgeDefaultPreference
          : messages.customWithdrawalAgePreference,
        {
          age: age,
        }
      ),
      value: age,
    })
  );

  return (
    <article className="transfer-pension-story-payout-plan">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            history.goBack();
          }
          if (lifeLong && repayment === true) {
            setIllegalCombination(true);
          } else {
            setTransfer({
              withdrawalAge: withdrawalAge,
              withdrawalMonths: withdrawalMonths,
              lifeLong: lifeLong,
              repayment: repayment,
              beneficiary: beneficiary,
            });
            next();
          }
        }}
      >
        <Typography type="h3" className="header">
          <TranslatedText id="sweden.transfer-pension.payout-plan.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.payout-plan.body" />
        </Typography>
        <Typography type="h4" className="heading">
          <TranslatedText id="sweden.transfer-pension.payout-plan.withdrawal-age.heading" />
        </Typography>
        <Select
          alternatives={withdrawalAgeAlternatives}
          label=" "
          placeholder={intl.formatMessage({
            id: "sweden.transfer-pension.payout-plan.withdrawal-age.select.placeholder",
          })}
          value={withdrawalAgeAlternatives.find(
            (alternative) => alternative.value === withdrawalAge
          )}
          onChange={(newValue) =>
            // setTransfer({ withdrawalAge: newValue.value })
            setWithdrawalAge(newValue.value)
          }
        />
        <Typography type="h4" className="heading">
          <TranslatedText id="sweden.transfer-pension.payout-plan.withdrawal-period.heading" />
        </Typography>
        <Select
          alternatives={withdrawalPeriodAlternatives}
          label=" "
          placeholder={intl.formatMessage({
            id: "sweden.transfer-pension.payout-plan.payout-period.select.placeholder",
          })}
          value={withdrawalPeriodAlternatives.find(
            (alternative) => alternative.value === withdrawalMonths / 12
          )}
          onChange={(newValue) => {
            setWithdrawalMonths(newValue.value * 12);
            setLifeLong(
              newValue.value === LIFE_LONG_ALTERNATIVE_VALUE ? true : false
            );
          }}
        />
        <Typography type="h3">
          <TranslatedText id="sweden.transfer-pension.payout-plan.repayment.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText
            id="sweden.transfer-pension.payout-plan.repayment.body"
            values={{
              readMore: (text) => {
                return (
                  <Button
                    inline
                    variant="outlined"
                    size="small"
                    label={text}
                    className="read-more"
                    onClick={handleModalOpen}
                  />
                );
              },
            }}
          />
        </Typography>
        {showModal && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.payout-plan.repayment.read-more.modal.header",
            })}
            showModal={!!showModal}
            onClose={handleModalClose}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.payout-plan.repayment.read-more.modal.first-paragraph" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.payout-plan.repayment.read-more.modal.second-paragraph" />
            </Typography>
          </Modal>
        )}
        <RadioGroupCard
          alternatives={[
            {
              header: intl.formatMessage({
                id: "sweden.transfer-pension.payout-plan.repayment.choice.yes",
              }),
              description: intl.formatMessage({
                id: "sweden.transfer-pension.payout-plan.repayment.choice.yes.description",
              }),
              value: true,
            },
            {
              header: intl.formatMessage({
                id: "sweden.transfer-pension.payout-plan.repayment.choice.no",
              }),
              description: intl.formatMessage({
                id: "sweden.transfer-pension.payout-plan.repayment.choice.no.description",
              }),
              value: false,
            },
          ]}
          legend=""
          onChange={(newValue) => {
            // setTransfer({ repayment: newValue });
            setRepayment(newValue);
          }}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.payout-plan.repayment.required",
              })
            ),
          ]}
          value={repayment}
        />
        {repayment === true && lifeLong === false && (
          <AnimatePresence>
            {repayment === true && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{
                  opacity: 0,
                  height: 0,
                }}
                transition={{ duration: 0.4 }}
              >
                <Typography type="h3" className="beneficiary-header">
                  <TranslatedText id="sweden.transfer-pension.payout-plan.beneficiary.header" />
                </Typography>
                <RadioGroupCard
                  alternatives={[
                    {
                      header: intl.formatMessage({
                        id: "sweden.transfer-pension.payout-plan.beneficiary.spouse-children",
                      }),
                      description: intl.formatMessage({
                        id: "sweden.transfer-pension.payout-plan.beneficiary.spouse-children.description",
                      }),
                      value: "SPOUSE_CHILDREN",
                    },
                    {
                      header: intl.formatMessage({
                        id: "sweden.transfer-pension.payout-plan.beneficiary.children-spouse",
                      }),
                      description: intl.formatMessage({
                        id: "sweden.transfer-pension.payout-plan.beneficiary.children-spouse.description",
                      }),
                      value: "CHILDREN_SPOUSE",
                    },
                    {
                      header: intl.formatMessage({
                        id: "sweden.transfer-pension.payout-plan.beneficiary.other",
                      }),
                      description: intl.formatMessage({
                        id: "sweden.transfer-pension.payout-plan.beneficiary.other.description",
                      }),
                      value: "OTHER", // Not super intiutive but decided. This alternative mean we send home a paper form. When we get that back we update the option on the move.
                      expandableContent: (
                        <section className="download">
                          <Typography type="body" variant="secondary">
                            <TranslatedText id="sweden.transfer-pension.payout-plan.beneficiary.other.download.info" />
                          </Typography>
                          <Button
                            component="a"
                            type="button"
                            download
                            variant="outlined"
                            icon="Download"
                            target="_blank"
                            block
                            label={intl.formatMessage({
                              id: "sweden.transfer-pension.payout-plan.beneficiary.other.download.button",
                            })}
                            href="/docs/pension-account/Blankett_formanstagare_4.pdf"
                          />
                        </section>
                      ),
                    },
                  ]}
                  legend=""
                  onChange={(newValue: Beneficiary) => {
                    // setTransfer({ beneficiary: newValue });
                    setBeneficiary(newValue);
                  }}
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage({
                        id: "sweden.transfer-pension.payout-plan.beneficiary.required",
                      })
                    ),
                  ]}
                  value={beneficiary}
                />
              </motion.div>
            )}
          </AnimatePresence>
        )}
        {lifeLong && repayment && (
          <section className="snack-bar">
            <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
              <TranslatedText id="sweden.transfer-pension.payout-plan.withdrawal-period.life-long-and-repayment.snackbar" />
            </Snackbar>
          </section>
        )}
        {illegalCombination && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.payout-plan.illegal-combination.modal.header",
            })}
            showModal={!!illegalCombination}
            onClose={() => setIllegalCombination(false)}
            closeOnOverlayClick
            width={420}
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.payout-plan.illegal-combination.modal.paragraph" />
            </Typography>
            <Button
              block
              type="button"
              label={intl.formatMessage({
                id: "sweden.transfer-pension.payout-plan.illegal-combination.modal.button",
              })}
              onClick={() => setIllegalCombination(false)}
            />
          </Modal>
        )}
        <Button
          block
          type="submit"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.payout-plan.next",
          })}
        />
      </Form>
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}
